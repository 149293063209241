import "./App.css";
import "./dashboard-new-styles.css";
import "./LeadSearch.css";
import "./BossPlans.css";
import "./BottomSheet.css";
import "./PaymentCss.css";
import "./BottomSheet.css";
import "./AfterPost.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Home from "./pages/Home";
import MainDashboard from "./pages/MainDashboard";
import { getSuperCategory } from "./redux/superCategory/SuperCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "./redux/category/CategorySlice";
import { getProperty, getPropertybyId } from "./redux/property/PropertySlice";
import { getSubCategory } from "./redux/subCategory/SubCategorySlice";
import Search from "./pages/Search";
import { Helmet } from "react-helmet";
import PropertyInfo from "./pages/PropertyInfo";
import AddProperty from "./pages/dashboard/AddProperty";
import PropertySearchPage from "./pages/search/PropertySearchPage";
import SwiperRecommendedProperty from "./pages/SwiperRecommendedProperty";
import PostProperty from "./pages/PropertyInfo/PostProperty";
import RecentelySearched from "./pages/PropertyInfo/RecentelySearched";
import ProjectByAliens from "./pages/PropertyInfo/ProjectByAliens";
import ShortListed from "./pages/PropertyInfo/ShortListed";
import Contacted from "./pages/PropertyInfo/Contacted";
import ViewedProjects from "./pages/PropertyInfo/ViewedProjects";
import Viewed from "./pages/PropertyInfo/Viewed";
import SimplePricing from "./pages/home/SimplePricing";
import All from "./pages/dashboard/components/All";
import {
  getUserViewProperty,
  getuserViewPropertyBYId,
} from "./redux/userViewdProperty/UserViewdPropertySlice";
import {
  authActions,
  userUpdateById,
  validatePhoneNumber,
} from "./redux/athentication/Athentication";
import SubcriptionPage from "./pages/SubcriptionPage";
import LeadSearch from "./pages/search/LeadSearch";
import LeadToSearch from "./pages/dashboard/LeadToSearch";
import TermsConditions from "./pages/footerpages/TermsConditions";
import PrivacyPolicy from "./pages/footerpages/PrivacyPolicy";
import AboutUs from "./pages/footerpages/AboutUs";
import ContactUs from "./pages/footerpages/ContactUs";
import RequestaProblem from "./pages/footerpages/RequestaProblem";
import SummonsNotices from "./pages/footerpages/SummonsNotices";
import Grievance from "./pages/footerpages/Grievance";
import SafetyGuide from "./pages/footerpages/SafetyGuide";
import CustomerService from "./pages/dashboard/CustomerService";
import PostPropertyHome from "./pages/postpropertypage/PostPropertyHome";
import ProjectInfo from "./pages/ProjectInfo";
import PostSuccess from "./pages/PostSuccess";
import SearchPropertyy from "./pages/SearchPropertyy";
import LeadsearchResult from "./pages/dashboard/LeadsearchResult";
import BossPlans from "./pages/dashboard/components/BossPlans";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import EditMainPropertyDetails from "./pages/editpost/EditMainPropertyDetails";
import EditProperty from "./pages/EditProperty";
import PaymentFail from "./pages/PaymentFail";
import PropertyInfoNew from "./pages/PropertyInfoNew";
import MainEditProperty from "./pages/EditPropertyTemp/MainEditProperty";
import Facebook from "./pages/Facebook";
import LocationPopup from "./LocationPopup";
import UpdatePropertyAll from "./pages/UpdatePropertyAll";
import PropertyImages from "./pages/propertyinfonew/PropertyImages";
import DeleteAccount from "./pages/DeleteAccount";
import DeleteSuccess from "./pages/deleteAccount/DeleteSuccess";

function App() {
  const { loginData, isAuth, show_model } = useSelector(
    (state) => state.Athentication
  );
  const dispatch = useDispatch();
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getSuperCategory
        dispatch(getSuperCategory());

        // getCategory
        dispatch(getCategory());

        // getSubCategory
        dispatch(getSubCategory());

        // getPropertyId
        dispatch(getPropertybyId(loginData._id));

        // getProperty
        dispatch(getProperty());

        // getUserViewProperty
        dispatch(getUserViewProperty());

        //  login here
        if (isAuth) {
          // getuserViewPropertyBYId
          dispatch(getuserViewPropertyBYId(loginData._id));
        }
      } catch (error) {}
    };
    initialSetup();
  }, [isAuth]);

  useEffect(() => {
    if (show_model === false) {
      if (
        // window.location.href === "http://localhost:3000/" ||
        // window.location.href === "http://localhost:3000" ||
        window.location.href === "www.mygoldenhomes.com/" ||
        window.location.href === "www.mygoldenhomes.com" ||
        window.location.href === "https://mygoldenhomes.com/" ||
        window.location.href === "https://mygoldenhomes.com" ||
        window.location.href === "http://mygoldenhomes.com/" ||
        window.location.href === "http://mygoldenhomes.com" ||
        window.location.href === "https://www.mygoldenhomes.com/" ||
        window.location.href === "https://www.mygoldenhomes.com" ||
        window.location.href === "http://www.mygoldenhomes.com/" ||
        window.location.href === "http://www.mygoldenhomes.com"
      ) {
        // window.location.replace("http://localhost:3001/facebook_ads");
        window.location.replace("https://www.mygoldenhomes.com/facebook_ads");
      }
    }
  }, [show_model]);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        {/* <LocationPopup /> */}
        <Routes>
          <Route path="/" exact element={<MainLayout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/facebook_ads" index element={<Facebook />} />

            {/* <Route
              path="/property-info/:apartname"
              index
              element={<PropertyInfoNew />}
            /> */}

            <Route
              path="/property-info/:apartname/:propertyid"
              index
              element={<PropertyInfoNew />}
            />

            {/*  <Route
              path="/project-info/:apartname"
              index
              element={<ProjectInfo />}
            /> */}

            <Route
              path="/swiperrecommendedproperty"
              index
              element={<SwiperRecommendedProperty />}
            />
            <Route path="/postproperty" index element={<PostProperty />} />
            <Route
              path="/recentelysearched"
              index
              element={<RecentelySearched />}
            />
            <Route path="/viewed" index element={<Viewed />} />
            <Route path="/shortlisted" index element={<ShortListed />} />
            <Route path="/contacted" index element={<Contacted />} />
            <Route path="/postproperty" index element={<PostProperty />} />
            <Route path="/simplepricing" index element={<SimplePricing />} />
            <Route path="/subcription" index element={<SubcriptionPage />} />
            <Route
              path="/customerservice"
              index
              element={<CustomerService />}
            />
            <Route
              path="/property-search/:supercat/:cat/:subcat/:key"
              index
              element={<SearchPropertyy />}
            />
          </Route>

          <Route path="/property-image" element={<PropertyImages />} />
          <Route path="/dashboard" element={<MainDashboard />} />
          <Route path="/search" index element={<Search />} />
          {/* not used right now */}
          <Route path="/property" index element={<AddProperty />} />

          <Route path="/leadtosearch" index element={<LeadToSearch />} />
          <Route
            path="/leadtosearch-reult"
            index
            element={<LeadsearchResult />}
          />
          <Route path="/aboutus" index element={<AboutUs />} />
          <Route path="/contactus" index element={<ContactUs />} />
          <Route
            path="/termsandconditions"
            index
            element={<TermsConditions />}
          />
          <Route path="/feedback" index element={<RequestaProblem />} />
          <Route path="/privacypolicy" index element={<PrivacyPolicy />} />
          <Route path="/summonsnotices" index element={<SummonsNotices />} />
          <Route path="/grievance" index element={<Grievance />} />
          <Route path="/safetyGuide" index element={<SafetyGuide />} />
          <Route path="/post-success" index element={<PostSuccess />} />
          <Route
            path="/postPropertyHome"
            index
            element={<PostPropertyHome />}
          />
          <Route path="/BossPlans" index element={<BossPlans />} />
          <Route
            path="/payment-process"
            index
            element={<PaymentSuccessPage />}
          />
          {/* <Route
            path="/edit-property/:postId"
            index
            element={<EditProperty />}
          /> */}
          <Route
            path="/edit-property/:postId"
            index
            element={<MainEditProperty />}
          />
          <Route
            path="/UpdatePropertyall"
            index
            element={<UpdatePropertyAll />}
          />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/delete-success" element={<DeleteSuccess />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
