import React, { useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";
import { BsHouseDoor, BsPencilFill } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { authActions, signout } from "../../redux/athentication/Athentication";

import MyProfile from "./MyProfile";
import MyAddress from "./MyAddress";
import moment from "moment";
import "./account.css";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const AccoutDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(true);

  const { loginData, clientid, deleteClientDetails } = useSelector(
    (store) => store.Athentication
  );

  console.log(deleteClientDetails, "deleteClientDetails");

  const logout = () => {
    dispatch(authActions.signout());
    navigate("/");
  };

  const handleDeleteAccount = async () => {
    try {
      const formData = {
        ClientId: deleteClientDetails._id,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/user/userdelete/${deleteClientDetails._id}`;
      const resp = await axios.delete(url, formData, config);
      
      if (resp.data.success) {
        dispatch(signout());
        toast.success("Account Deleted Successfully", {
          position: "top-center",
          autoClose: 500,
        });
        navigate("/delete-success");
      }
    } catch (error) {}
  };
  return (
    <>
      {/* <section className="user-dashboard-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="row">
              <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                <div className="image-contain">
                  <img
                    src="../assets/images/inner-page/otp.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="log-in-box">
                    <div className="log-in-title">
                      <div
                        className="tab-pane fade show active"
                        id="pills-dashboard"
                        role="tabpanel"
                        aria-labelledby="pills-dashboard-tab"
                      >
                        <div className="dashboard-home">
                          <div className="d-flex justify-content-between">
                            <div className="title pt-2">
                              <h2>Account Details</h2>
                            </div>
                          </div>

                          <div className="profile-tab dashboard-bg-box">
                            <div className="dashboard-title dashboard-flex">
                              <h3>Profile Name</h3>
                            </div>
                            <ul>
                              <li className="d-flex">
                                <h5>Name :</h5>
                                <h5>{deleteClientDetails.name}</h5>
                              </li>
                              <li className="d-flex">
                                <h5>Email Address :</h5>
                                <h5>{deleteClientDetails.email}</h5>
                              </li>
                              <li className="d-flex">
                                <h5>Country / Region :</h5>
                                <h5>India</h5>
                              </li>
                              <li className="d-flex">
                                <h5>Year Registared :</h5>
                                <h5>
                                  {moment(deleteClientDetails.createdAt).format(
                                    "YYYY"
                                  )}
                                </h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 layout-top-spacing">
            <div className="user-profile">
              <div className="widget-content widget-content-area">
                <div className="d-flex justify-content-between">
                  <h3 className>Profile</h3>
                </div>
                <div className="text-center user-info">
                  <img
                    src="https://bootdey.com/img/Content/avatar/avatar1.png"
                    alt="avatar"
                  />
                  <p className>{deleteClientDetails.name}</p>
                </div>
                <div className="user-info-list">
                  <div className>
                    <ul className="contacts-block list-unstyled">
                      <li className="contacts-block__item">
                        <a href="mailto:example@mail.com">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-mail me-3"
                          >
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                            <polyline points="22,6 12,13 2,6" />
                          </svg>
                          {deleteClientDetails.email}
                        </a>
                      </li>
                      <li className="contacts-block__item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-phone me-3"
                        >
                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                        </svg>{" "}
                        {deleteClientDetails.mobile}
                      </li>
                      <li className="contacts-block__item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-coffee me-3"
                        >
                          <path d="M18 8h1a4 4 0 0 1 0 8h-1" />
                          <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />
                          <line x1={6} y1={1} x2={6} y2={4} />
                          <line x1={10} y1={1} x2={10} y2={4} />
                          <line x1={14} y1={1} x2={14} y2={4} />
                        </svg>{" "}
                        {deleteClientDetails.packageType}
                      </li>
                      <li className="contacts-block__item">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-coffee me-3"
                        >
                          <path d="M18 8h1a4 4 0 0 1 0 8h-1" />
                          <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />
                          <line x1={6} y1={1} x2={6} y2={4} />
                          <line x1={10} y1={1} x2={10} y2={4} />
                          <line x1={14} y1={1} x2={14} y2={4} />
                        </svg>{" "}
                        {deleteClientDetails.role}
                      </li>
                    </ul>
                    <ul className="list-inline mt-4">
                      <li className="list-inline-item mb-0">
                        <a className="btn btn-info btn-icon btn-rounded _effect--ripple waves-effect waves-light">
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDeleteAccount()}
                          >
                            Delate Account
                          </button>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccoutDetails;
