import React, { useState, useEffect, useRef } from "react";
import { BsHouseDoor } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deletepageStatusChange,
  loginAuthentication,
} from "../../redux/athentication/Athentication";
import { pageStatusChange } from "../../redux/property/PropertySlice";

const OtpVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = useRef();

  const { mob, otp, deleteClientDetails } = useSelector(
    (state) => state.Athentication
  );

  const [registerShow, setRegisterShow] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [timeShow, setTimeShow] = useState(false);
  const [timer, setTimer] = useState(30);
  const [btnLoading, setBtnLoading] = useState(false);

  const OtpChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setOtpValue(value);
  };

  const resendClick = () => {
    setTimer(30);
    const formdata = {
      mobile: mob,
      OTP: otp,
    };
    dispatch(loginAuthentication(formdata));
  };

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    setTimeShow(false);
    return () => clearInterval(counter);
  }, [timer]);

  const handleSubmit = async () => {
    setErrormsg("");
    setBtnLoading(true);

    if (otpValue.length === 6) {
      if (otpValue === otp) {
        dispatch(deletepageStatusChange(2));
      } else {
        setErrormsg("Please enter correct OTP");
      }
    }
  };

  return (
    <>
      <div className="custom-form">
        <div name="Otpform">
          <label>
            Verify Otp *
            <span className="dec-icon">
              <i className="fal fa-user" />
            </span>
          </label>
          <input
            name="email"
            type="text"
            placeholder="Enter Otp"
            required
            minLength={6}
            maxLength={6}
            value={otpValue}
            onChange={(e) => OtpChange(e)}
          />
          <div className="errorOtp">{errormsg}</div>
          {timer > 0 ? (
            <>
              <div className="lost_password">
                <a>Resend OTP again in 0:{timer} secs</a>
              </div>
            </>
          ) : (
            <>
              <div className="lost_password" onClick={() => resendClick()}>
                <a>Resend Otp</a>
              </div>
            </>
          )}
          <div className="filter-tags">
            <input id="check-a3" type="checkbox" name="check" />
            <label htmlFor="check-a3">Remember me</label>
          </div>
          <div className="clearfix" />
          {btnLoading === true ? (
            <>
              <button className="log_btn color-bg">Logging You...</button>
            </>
          ) : (
            <>
              <button className="log_btn color-bg" onClick={handleSubmit}>
                Validate
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
