import React, { useEffect, useId, useRef, useState } from "react";
import Login from "../../components/Logins/Login";
import Registration from "../../components/Logins/Registration";
import { Link } from "react-router-dom";
import {
  setModelShow,
  authActions,
} from "../../redux/athentication/Athentication";
import { useDispatch, useSelector } from "react-redux";
import { MdHeadsetMic, MdKeyboardArrowUp } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { MdPhoneCallback } from "react-icons/md";
import {
  pageStatusChange,
  setuserIdRemove,
} from "../../redux/property/PropertySlice";
import { useNavigate } from "react-router-dom/dist";
import { IoSearchSharp } from "react-icons/io5";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { searchPost, setsearchObjects } from "../../redux/search/SearchSlice";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { MdKeyboardArrowDown } from "react-icons/md";
import { LuSearch } from "react-icons/lu";
import HeaderSearchMob from "./HeaderSearchMob";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./HeaderSearch.css";

const HeaderSearch = (props) => {
  const { modelShow } = useSelector((store) => store.Athentication);
  const { loginData, isAuth } = useSelector((store) => store.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { catTotal } = useSelector((store) => store.Category);

  const [buychange, setBuychange] = useState(true);
  const [rentchange, setRentchange] = useState(false);

  const [menubar, setMenubar] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Buy");

  const [propertyId, setPropertyId] = useState("");
  const [searchError, setSearchError] = useState("");

  const [propertySearch, setPropertySearch] = useState([]);
  const [showresults, setShowresults] = useState(false);
  const [propertySearchProject, setPropertySearchProject] = useState([]);

  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [searchArray, setSearchArray] = useState([]);
  const [showAllApart, setShowAllApart] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [propertySearchShow, setPropertySearchShow] = useState([]);
  const [propertyShowByApart, setPropertyShowByApart] = useState([]);
  const [propertySearchShowByCity, setPropertySearchShowByCity] = useState([]);

  const searchChange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/localitieswithcity`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.locality;
              setPropertySearch(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}

    try {
      if (search.length >= 2) {
        const fetchPropertyByProjects = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertyproject/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties;
              setPropertySearchProject(searchresult);
            }
          } catch (error) {}
        };

        fetchPropertyByProjects();
      }
    } catch (error) {}

    setShowResults(true);
  }, [search]);

  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/apartmentswithcity`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.apartment;
              setShowAllApart(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}
  }, [search]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/allcities`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const cityresult = fetchProperty.data.cities;
          setCitylist(cityresult);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (propertySearch.length > 0 && search !== "") {
      const searchfilter = propertySearch.filter((item) =>
        item.locality.toLowerCase().match(search.toLowerCase())
      );
      setPropertySearchShow(searchfilter.slice(0, 5));

      const searchfilterbyapart = showAllApart.filter((item) =>
        item.apartment.toLowerCase().match(search.toLowerCase())
      );
      setPropertyShowByApart(searchfilterbyapart.slice(0, 5));

      const searchfilterbycity = citylist.filter((item) =>
        item.toLowerCase().match(search.toLowerCase())
      );
      setPropertySearchShowByCity(searchfilterbycity.slice(0, 5));
    }
  }, [propertySearch, search, citylist, showAllApart]);

  const storeAllApartInarray = (e, data) => {
    let apartmentArray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter(
        (seitem) => seitem === data.apartment
      );
      if (findserchname.length === 0) {
        apartmentArray = [data.apartment, ...searchArray];
        setSearchArray(apartmentArray);
      }
    } else {
      apartmentArray = [data.apartment];
      setSearchArray(apartmentArray);
    }
    setSearch("");
    setShowResults(false);
  };

  const storeCityAllInarray = (e, data) => {
    let cityarray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter((seitem) => seitem === data);
      if (findserchname.length === 0) {
        cityarray = [data, ...searchArray];
        setSearchArray(cityarray);
      }
    } else {
      cityarray = [data];
      setSearchArray(cityarray);
    }
    setSearch("");
    setShowResults(false);
  };

  const storeCityInarray = (e, data) => {
    let localirtarray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter(
        (seitem) => seitem === data.locality
      );
      if (findserchname.length === 0) {
        localirtarray = [data.locality, ...searchArray];
        setSearchArray(localirtarray);
      }
    } else {
      localirtarray = [data.locality];
      setSearchArray(localirtarray);
    }

    setSearch("");
    setShowResults(false);
  };

  const projectRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/project-info/${data.apartment}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };

  const removesearch = (data) => {
    const filteredArr = searchArray.filter((item) => item !== data);
    setSearchArray(filteredArr);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setPropertyId(inputValue);
    if (searchError !== "") {
      setSearchError("");
    }
  };

  const SearchBtn = async () => {
    if (propertyId !== "") {
      const paramstext = propertyId.toUpperCase();
      const url = `${Baseurl}/api/v1/property/propertybyprojectid/${paramstext}`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const searchresult = fetchProperty.data.property;
          if (searchresult.apartment !== "") {
            navigate(
              `/property-info/${searchresult.apartment}/${searchresult.propertyID}`
            );
          } else {
            navigate(`/property-info/commercial/${searchresult.propertyID}`);
          }
          setMenubar(false);
          setPropertyId("");
        }
      } catch (error) {}
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      if (propertyId !== "") {
        const paramstext = propertyId.toUpperCase();

        const url = `${Baseurl}/api/v1/property/propertybyprojectid/${paramstext}`;
        const fetchProperty = await axios.get(url);
        try {
          if (fetchProperty.data.success === true) {
            const searchresult = fetchProperty.data.property;
            if (searchresult.apartment !== "") {
              navigate(
                `/property-info/${searchresult.apartment}/${searchresult.propertyID}`
              );
            } else {
              navigate(`/property-info/commercial/${searchresult.propertyID}`);
            }
            setMenubar(false);
            setPropertyId("");
          }
        } catch (error) {}
      }
    }
  };

  const logoutClick = () => {
    dispatch(authActions.signout());
    dispatch(setuserIdRemove());
    navigate("/");
    window.location.reload(false);
  };

  const items = [
    {
      label: (
        <a href="#" style={{ color: "#bababa", textAlign: "start" }}>
          Residental
        </a>
      ),
      key: "residential",
      supCat: "Residential",
    },
    {
      type: "divider",
    },
    {
      label: "Buy",
      key: "buyResidential",
      supCat: "Residential",
    },
    {
      label: "Rent",
      key: "rentResidential",
      supCat: "Residential",
    },
    {
      label: "Pg",
      key: "pgResidential",
      supCat: "Residential",
    },
    {
      label: "Projects",
      key: "projectsResidential",
      supCat: "Residential",
    },
    {
      label: (
        <a href="#" style={{ color: "#bababa", textAlign: "start" }}>
          Commercial
        </a>
      ),
      key: "commercial",
      supCat: "Commercial",
    },
    {
      type: "divider",
    },
    {
      label: "Buy",
      key: "buyCommercial",
      supCat: "Commercial",
    },
    {
      label: "Lease",
      key: "leaseCommercial",
      supCat: "Commercial",
    },
    {
      label: "Projects",
      key: "projectsCommercial",
      supCat: "Commercial",
    },
  ];
  
  const [selectedsupcat, setSelectedsupcat] = useState("");
  const [selectedItem, setSelectedItem] = useState("Rent");
  const [open, setopen] = useState(false);
  const [open2, setopen2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const cahngesupcat = props.changeSupCat;
    const serachdata = props.searchArray;
    const cahngecat = props.changeCat;
    setSelectedsupcat(cahngesupcat);
    setSelectedItem(cahngecat === "Sell" ? "Buy" : cahngecat);
    setSearchArray([...serachdata]);
  }, [props.searchArray, props.changeCat]);

  const selectRemoveClick = (data) => {
    const filterdata = props.searchArray.filter((item) => item !== data);
    setSearchArray(filterdata);
  };


  const HandleOpen = (e) => {
    const inputValue = e.target.value;
    setopen(inputValue.length <= 0);
  };
  const HandleOpen2 = (e) => {
    const inputValue = e.target.value;
    setopen2(inputValue.length > 0);
  };

  const handleMenuClick = ({ key }) => {
    const selected = items.find((item) => item.key === key);
    if (selected) {
      setSelectedItem(
        selected.label.props
          ? selected.label.props.children
          : selected.label === "Buy"
          ? "Sell"
          : selected.label
      );
      setSelectedsupcat(selected.supCat);
    }
    setIsOpen(false);
  };

  const advanceSearchClick = () => {
    props.setChangeSupCat(selectedsupcat);
    props.setChangeCat(selectedItem);
    props.setSearchArray(searchArray);
  };


  const HandleProperty = () => {
    setIsOpen2((prev) => !prev);
  };

  function CrossClick() {
    const a = true;
    if (a) {
      document.getElementById("madha").style.display = "none";
    } else {
      document.getElementById("madha").style.display = "block";
    }
  }

  function Allhide() {
    const a = true;
    if (a) {
      document.getElementById("opn").style.display = "none";
    }
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ...";
    }
    return text;
  };

  return (
    <>
      <header className="main-header" id="headersearchdesk">
        <div className="mainsection">
          <div className="logo-holder" style={{ width: "20%" }}>
            <Link to="/">
              <img src="/assets/images/logo3.png" alt="" />
            </Link>
          </div>

          <div className="second">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <div className="dropdown">
                <Dropdown
                  menu={{ items, onClick: handleMenuClick }}
                  trigger={["click"]}
                  onOpenChange={handleClick}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <span style={{ fontFamily: "Roboto" }}>
                        {selectedItem}
                      </span>
                      {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                    </Space>
                  </a>
                </Dropdown>
              </div>
       

              {searchArray.length > 0 ? (
                <>
                  <div id="madha" className="madha">
                    <div>
                      <p
                        style={{
                          color: "#000",
                          marginTop: "8px",
                          fontFamily: "sans-serif",
                        }}
                      >
                       {searchArray[0] ? truncateText(searchArray[0], 2) : ""}
                      </p>
                    </div>
                    <div>
                      <span
                        onClick={() => selectRemoveClick(searchArray[0])}
                        style={{ marginTop: "5px" }}
                      >
                        <RxCross2 color="#0177DC" size={17} />
                      </span>
                    </div>
                  </div>
                  {searchArray.length > 1 ? (
                    <>
                      <div id="madha" className="madha2">
                        <p
                          style={{
                            color: "#000",
                            marginTop: "8px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          + {searchArray.length - 1} More
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <div>
                <input
                  type="search"
                  placeholder='Search "Hyderabad"'
                  className="addmoresearch"
                  name="keyword"
                  id="Open"
                  ref={ref}
                  value={search}
                  onChange={(e) => searchChange(e)}
                />
              </div>
            </div>
            <div onClick={() => advanceSearchClick()}>
              <LuSearch color="#42516D" size={26} />
            </div>
          </div>

          <div className="third">
            <div>
              <div className="nav-button-wrap color-bg nvminit">
                <div className="nav-button">
                  <span />
                  <span />
                  <span />
                </div>
              </div>

              <div className="nav-holder main-menu">
                <nav>
                  <ul className="no-list-style">
                    <li>
                      <Link>
                        <button
                          type="button"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          style={{
                            border: "none",
                            background: "none",
                            margin: "5px",
                          }}
                          onClick={() => setMenubar(!menubar)}
                        >
                          <img
                            src="/assets/images/newimages/menu.png"
                            alt=""
                            style={{ height: "26px", width: "26px" }}
                          />
                        </button>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              {isAuth === true ? (
                <>
                  <div className="add-list_wrap">
                    <Link to="/PostPropertyHome" className="add-list color-bg">
                      <i className="fal fa-plus" />
                      <span>
                        Post Property
                        <span
                          style={{
                            background: "#219653",
                            color: "#fff",
                            padding: "2px 5px",
                            borderRadius: "3px",
                            fontWeight: "700",
                            lineHeight: "9px",
                            letterSpacing: ".02em",
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          Free
                        </span>
                      </span>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="add-list_wrap modal-open"
                    onClick={() => dispatch(setModelShow(true))}
                  >
                    <Link className="add-list color-bg">
                      <i className="fal fa-plus" />{" "}
                      <span>
                        Post Property
                        <span
                          style={{
                            background: "#219653",
                            color: "#fff",
                            padding: "2px 5px",
                            borderRadius: "3px",
                            fontWeight: "700",
                            lineHeight: "9px",
                            letterSpacing: ".02em",
                            textTransform: "uppercase",
                            fontSize: "10px",
                          }}
                        >
                          Free
                        </span>
                      </span>
                    </Link>
                  </div>
                </>
              )}
              {isAuth === true ? (
                <>
                  <div className="nav-holder main-menu">
                    <nav>
                      <ul className="no-list-style">
                        <li>
                          <Link>
                            {loginData.name}
                            <i className="fa fa-caret-down" />
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/dashboard"
                                style={{
                                  fontSize: "14px",
                                  color: "goldenrod",
                                  fontWeight: "600",
                                }}
                              >
                                My MGH
                              </Link>
                            </li>
                            <li>
                              <Link to="">Recently Searched</Link>
                            </li>
                            <li>
                              <Link to="">Recently Viewed</Link>
                            </li>
                            <li>
                              <Link to="">Shortlisted</Link>
                            </li>
                            <li>
                              <Link to="">Contacted</Link>
                            </li>
                            <li>
                              <Link to="">Rent Transaction</Link>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/dashboard");
                                dispatch(pageStatusChange(4));
                              }}
                            >
                              <Link to="">Manage Listings</Link>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/dashboard");
                                dispatch(pageStatusChange(21));
                              }}
                            >
                              <Link to="">View All Responses</Link>
                            </li>
                            <li onClick={() => logoutClick()}>
                              <Link to="">Logout</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="show-reg-form modal-open"
                    onClick={() => dispatch(setModelShow(true))}
                  >
                    <i className="fas fa-user" />
                    <span>Sign In</span>
                  </div>
                </>
              )}

              <div className="nav-holder main-menu">
                <nav>
                  <ul className="no-list-style">
                    <li>
                      <ul>
                        <li>
                          <Link>Contact us</Link>
                        </li>
                        <li>
                          <Link>
                            <div className="classforheadfon">
                              <div className="headfonIcon">
                                <MdCall color="#253858" fontSize="18px" />
                              </div>
                              <div className="headfonCall">
                                <div className="tollfree">
                                  Toll Free | 9 AM - 8 PM IST
                                </div>
                                <div className="tollfreeNumber">
                                  +91 9063483711
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link>
                            <div className="classforheadfon1">
                              <div className="requestcallIcon">
                                <MdPhoneCallback className="requestIconCo" />
                              </div>
                              <div className="requestcall">
                                Request a Call Back
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* search-wrapper */}
              {/* <div className="header-search-wrapper novis_search">
                <div className="header-serach-menu">
                  <div className="custom-switcher fl-wrap">
                    <div className="fieldset fl-wrap">
                      <input
                        type="radio"
                        name="duration-1"
                        id="buy_sw"
                        className="tariff-toggle"
                        defaultChecked
                      />
                      <label htmlFor="buy_sw">Buy</label>
                      <input
                        type="radio"
                        name="duration-1"
                        className="tariff-toggle"
                        id="rent_sw"
                      />
                      <label htmlFor="rent_sw" className="lss_lb">
                        Rent
                      </label>
                      <span className="switch color-bg" />
                    </div>
                  </div>
                </div>
                <div className="custom-form">
                  <form name="registerform">
                    <label>Keywords </label>
                    <input
                      type="text"
                      placeholder="Address , Street , State..."
                      defaultValue=""
                    />
                    <label>Categories</label>
                    <select
                      data-placeholder="Categories"
                      className="chosen-select nice-select on-radius no-search-select"
                    >
                      <option>All Categories</option>
                      <option>House</option>
                      <option>Apartment</option>
                      <option>Hotel</option>
                      <option>Villa</option>
                      <option>Office</option>
                    </select>
                    <label style={{ marginTop: 10 }}>Price Range</label>
                    <div className="price-rage-item fl-wrap">
                      <input
                        type="text"
                        className="price-range"
                        data-min={100}
                        data-max={100000}
                        name="price-range1"
                        data-step={1}
                        defaultValue={1}
                        data-prefix="₹"
                      />
                    </div>
                    <button type="submit" className="btn float-btn color-bg">
                      <i className="fal fa-search" /> Search
                    </button>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {showResults && (
          <>
            {propertyShowByApart.length > 0 ? (
              <>
                <div id="opn2">
                  <div style={{ width: "96%" }}>
                    {propertyShowByApart &&
                      propertyShowByApart.map((apart, index) => (
                        <div
                          className="suggestion"
                          key={index}
                          onClick={(e) => storeAllApartInarray(e, apart)}
                        >
                          <div>
                            <p>{apart.apartment}</p>
                          </div>
                          <div>
                            <p>{apart.subCategory}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {propertySearchShowByCity.length > 0 ? (
              <>
                <div id="opn2">
                  <div style={{ width: "96%" }}>
                    {propertySearchShowByCity &&
                      propertySearchShowByCity.map((city, index2) => (
                        <div
                          className="suggestion"
                          key={index2}
                          onClick={(e) => storeCityAllInarray(e, city)}
                        >
                          <div>
                            <p>{city}</p>
                          </div>
                          <div>
                            <p>All</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {propertySearchShow.length > 0 ? (
              <>
                <div id="opn2">
                  <div style={{ width: "96%" }}>
                    {propertySearchShow &&
                      propertySearchShow.map((data, index) => (
                        <div
                          className="suggestion"
                          key={index}
                          onClick={(e) => storeCityInarray(e, data)}
                        >
                          <div>
                            <p> {data.locality + "," + data.city}</p>
                          </div>
                          <div>
                            <p>{data.city}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {propertySearchProject.length > 0 ? (
              <>
                <div id="opn2">
                  <div style={{ width: "96%" }}>
                    {propertySearchProject &&
                      propertySearchProject.map((data, index) => (
                        <div
                          className="suggestion"
                          key={index}
                          onClick={(e) => projectRedirect(e, data)}
                        >
                          <div>
                            <p> {data.apartment}</p>
                          </div>
                          <div>
                            <p>Project</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {/* <div
          id="opn"
          className="clearall"
          style={{ display: open ? "block" : "none" }}
        >
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div>
              <h5 style={{ color: "#A2AAB8" }}>Filters</h5>
            </div>
            <div>
              <h5 style={{ color: "#1F88DF" }}>Clear all Filters</h5>
            </div>
          </div>
          <div className="propertytypecontainer">
            <div className="property" onClick={HandleProperty}>
              Property Types(7)
              {isOpen2 ? (
                <IoIosArrowUp style={{ marginTop: "5px" }} />
              ) : (
                <IoIosArrowDown style={{ marginTop: "5px" }} />
              )}
            </div>
            <div className="budget">
              Budget
              <IoIosArrowDown style={{ marginTop: "5px" }} />
            </div>
            <div className="budget">
              Budget
              <IoIosArrowDown style={{ marginTop: "5px" }} />
            </div>
            <div className="budget">
              Budget
              <IoIosArrowDown style={{ marginTop: "5px" }} />
            </div>
            <div className="budget">
              Budget
              <IoIosArrowDown style={{ marginTop: "5px" }} />
            </div>
          </div>
          <div style={{ display: isOpen2 ? "block" : "none" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div className="apart">
                <input type="checkbox" name="" id="" />
                <div>Flat/Apartment</div>
              </div>
              <div className="apart">
                <input type="checkbox" name="" id="" />
                <div>Flat/Apartment</div>
              </div>
              <div className="apart">
                <input type="checkbox" name="" id="" />
                <div>Flat/Apartment</div>
              </div>
              <div className="apart">
                <input type="checkbox" name="" id="" />
                <div>Flat/Apartment</div>
              </div>
            </div>
          </div>

          <div className="btncontainer">
            <div className="searchbtn">Search</div>
            <div className="cancelbtn" onClick={Allhide}>
              Cancel
            </div>
          </div>
        </div> */}
        {/*search-wrapper end  */}
      </header>
      {menubar === true ? (
        <>
          <div className=" menubar">
            <div className="dashbard-menu-close">
              <i className="fal fa-times" />
            </div>
            <div className="dashbard-menu-container">
              <div className="user-profile-menu">
                <h3 style={{ textTransform: "capitalize" }}>
                  {loginData.name}
                </h3>
                <ul className="no-list-style">
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/dashboard")}
                  >
                    <a>
                      <i className="fal fa-chart-line" />
                      Dashboard
                    </a>
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(9));
                    }}
                  >
                    <a>
                      <i className="fal fa-user-edit" />
                      Packages
                    </a>
                  </li>
                </ul>
              </div>
              <div className="user-profile-menu">
                <h3>Listings</h3>
                <ul className="no-list-style">
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(4));
                    }}
                  >
                    <a>
                      <i className="fal fa-th-list" /> Your Properties
                    </a>
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(pageStatusChange(21));
                    }}
                  >
                    <a className="user-profile-act">
                      <i className="fal fa-calendar-check" /> All Responses
                    </a>
                  </li>
                </ul>
              </div>
              <div className="container searchcon">
                <div className="menusearch">
                  <input
                    type="text "
                    id="propertyId"
                    style={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                      background: "none",
                    }}
                    value={propertyId}
                    onChange={handleChange}
                    placeholder="Search by Property ID"
                    className="menuseachinput"
                    onKeyDown={handleKeyDown}
                  />
                  <IoSearchSharp
                    onClick={SearchBtn}
                    size={24}
                    style={{ cursor: "pointer" }}
                    className="menusearchicon"
                  />
                </div>
              </div>
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {searchError}
              </p>
            </div>

            <div className="dashbard-menu-footer">
              <p className="strong_medium">
                Toll Free Number:
                <span style={{ color: "#0078db" }}>+91 90634 83711. </span>
                For international number
              </p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        className="main-register-wrap modal"
        style={modelShow === true ? { display: "block" } : { display: "none" }}
      >
        {/* <div className="reg-overlay" /> */}
        <div className="main-register-holder tabs-act">
          <div
            className={
              modelShow
                ? "main-register-wrapper modal_main fl-wrap vis_mr"
                : "main-register-wrapper modal_main fl-wrap"
            }
          >
            <div className="main-register-header color-bg">
              <div className="main-register-logo fl-wrap">
                <img src="./assets/images/white-logo1.png" alt="" />
              </div>
              <div className="main-register-bg">
                <div className="mrb_pin" />
                <div className="mrb_pin mrb_pin2" />
              </div>
              <div className="mrb_dec" />
              <div className="mrb_dec mrb_dec2" />
            </div>
            <div className="main-register">
              <div className="close-reg">
                <i className="fal fa-times" />
              </div>
              <ul className="tabs-menu fl-wrap no-list-style">
                <li className="current">
                  <a href="#tab-1">
                    <i className="fal fa-sign-in-alt" /> Login
                  </a>
                </li>
                <li>
                  <a href="#tab-2">
                    <i className="fal fa-user-plus" /> Register
                  </a>
                </li>
              </ul>

              <div className="tabs-container">
                <div className="tab">
                  <div id="tab-1" className="tab-content first-tab">
                    <Login />
                  </div>

                  <div className="tab">
                    <div id="tab-2" className="tab-content">
                      <Registration />
                    </div>
                  </div>
                </div>

                <div
                  className="log-separator fl-wrap"
                  style={{ visibility: "hidden", opacity: "0" }}
                >
                  <span>or</span>
                </div>
                <div
                  className="soc-log fl-wrap"
                  style={{ visibility: "hidden", opacity: "0" }}
                >
                  <p>For faster login or register use your social account.</p>
                  <Link to="#" className="facebook-log">
                    Facebook
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mob-search-head">
        <HeaderSearchMob />
      </div> */}
    </>
  );
};

export default HeaderSearch;
