import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const ResponsesNewModal = (props) => {
  const { loginData } = useSelector((store) => store.Athentication);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loadData, setLoadData] = useState([]);

  console.log(props.response, "props.response");

  useEffect(() => {
    let finalexport = [];
    let formdata = {};

    for (let index = 0; index < props.response.length; index++) {
      let element = props.response[index];

      let createdAt = element.createdAt ? new Date(element.createdAt) : null;
      let receivedDate = createdAt ? createdAt.toLocaleDateString() : "N/A";
      let receivedTime = createdAt ? createdAt.toLocaleTimeString() : "N/A";

      let userType =
        element.userOwnerShip === "User" ? "Individual" : element.userOwnerShip;

      let interestedIn = "";
      if (element.noofBedrooms) {
        interestedIn += `${element.noofBedrooms} BHK | `;
      }
      interestedIn += `${element.subcategory} for ${element.category} in ${element.propertyName} ${element.locality}`;

      let productCode = element.propertyId ? element.propertyId : '';

      formdata = {
        Sno: index + 1,
        Name: element.userName,
        Type: userType,
        ContactNo: element.userMobile,
        PhoneVerificationStatus: "",
        EmailId: element.userEmail,
        Query: "",
        CalledOn: receivedDate,
        Time: receivedTime,
        Duration: "",
        CallStatus: "",
        Url: "",
        ReceivedDate: receivedDate,
        InterestedIn: interestedIn,
        ResponseType: "",
        Username: element.postUserName,
        AssignedTo: "",
        EmailVerificationStatus: "",
        Questionnaire: "",
        ProductCode: productCode,
        ProductType: "",
        IntentVerificationStatus: "",
        LeadScore: "",
        FollowupCurrentStatus: "",
        ProdType: "",
        City: element.city,
        Project: "",
        ResCom: "",
        Bhk: element.noofBedrooms,
        PropertySnapshot: "",
        ParentProductDetails: "",
        SetIsParentProductTypeResponse: "",
        CompactLabel: "",
        Duplicate: "",
      };

      finalexport = [...finalexport, formdata];
    }

    setLoadData([...finalexport]);
  }, [props.response]);

  // useEffect(() => {
  //   let filtResponce = props.response;

  //   if (fromDate !== "" && toDate !== "") {
  //     filtResponce = filtResponce.filter(
  //       (responce) =>
  //         moment(responce.createdAt).format("YYYY-MM-DD") >= fromDate &&
  //         moment(responce.createdAt).format("YYYY-MM-DD") <= toDate
  //     );
  //   }

  //   setLoadData(filtResponce);
  // }, [props.response, fromDate, toDate]);

  return (
    <>
      <div
        style={{
          justifyContent: "right",
          display: "flex",
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 1,
        }}
      >
        <RxCross1 size={20} color="#000" onClick={() => props.toggle()} />
      </div>
      <div
        style={{ background: "#fff", padding: "20px", position: "relative" }}
      >
        <div className="row">
          <div className="col-6 col-md-6 col-sm-12 col-sm-12 modalboxone">
            <div>
              <div className="d-flex mb-2">
                <label className="labeltext">Select Start Date: </label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="d-flex">
                <label className="labeltext">Select End Date: </label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-sm-12 col-sm-12 modalboxtwo">
            <CSVLink
              data={loadData}
              filename={"mgh-response-data.csv"}
              onClick={() => {}}
            >
              <button
                className="mt-0 btn btn-primary mb-3 download-responses-button"
                style={{
                  background: "#3270fc",
                  border: "1px solid #3270fc",
                  padding: "12px",
                  color: "#fff",
                  borderRadius: "3px",
                }}
              >
                Download Responses
              </button>
            </CSVLink>

            <p
              className="text-primary ms-4 download-raw-file"
              style={{ fontWeight: "500" }}
            >
              Download Raw File
            </p>
          </div>
        </div>

        <p className="please-paragraph">
          Please note that report will have responses upto 6 months from
          selected date
        </p>
      </div>
    </>
  );
};

export default ResponsesNewModal;
