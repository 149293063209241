
import React, { useEffect, useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

const FurnishingTemp = (props) => {
  const { PropertyProfile, BasicDetails,singleProperty } = useSelector(
    (state) => state.Property
  );

  const dispatch = useDispatch();

  const [furnish, setFurnish] = useState(Boolean(0));
  const [semifurnish, setSemifurnish] = useState(Boolean(0));
  const [unfurnish, setUnfurnish] = useState(Boolean(1));
  const [furnishedStatus, setFurnishedStatus] = useState("Un-furnished");

  const [light, setLight] = useState(singleProperty.numberoflight);
  const [fans, setFans] = useState(singleProperty.numberoffans);
  const [ac, setAc] = useState(singleProperty.numberofac);
  const [tv, setTv] = useState(singleProperty.numberoftv);
  const [beds, setBeds] = useState(singleProperty.numberofbeds);
  const [wardrobe, setWardrobe] = useState(singleProperty.numberofwardrobe);
  const [geyser, setGeyser] = useState(singleProperty.numberofgeyser);
  const [sofa, setSofa] = useState(singleProperty.sofa);
  const [washingMachine, setWashingMachine] = useState(singleProperty.washingMachine);
  const [stove, setStove] = useState(singleProperty.stove);
  const [fridge, setFridge] = useState(singleProperty.fridge);
  const [waterPurifier, setWaterPurifier] = useState(singleProperty.waterPurifier);
  const [microwave, setMicrowave] = useState(singleProperty.microwave);
  const [modularKitchen, setModularKitchen] = useState(singleProperty.modularKitchen);
  const [chimney, setChimney] = useState(singleProperty.chimney);
  const [dinningTable, setDinningTable] = useState(singleProperty.dinningTable);
  const [curtains, setCurtains] = useState(singleProperty.curtains);
  const [exhaustFan, setExhaustFan] = useState(singleProperty.exhaustFan);

  useEffect(() => {
    if (PropertyProfile !== "") {
      setFurnish(PropertyProfile.furnish);
      setSemifurnish(PropertyProfile.semifurnish);
      setUnfurnish(PropertyProfile.unfurnish);

      setLight(PropertyProfile.light);
      setFans(PropertyProfile.fans);
      setAc(PropertyProfile.ac);
      setTv(PropertyProfile.tv);
      setBeds(PropertyProfile.beds);
      setWardrobe(PropertyProfile.wardrobe);
      setGeyser(PropertyProfile.geyser);
      setSofa(PropertyProfile.sofa);
      setWashingMachine(PropertyProfile.washingMachine);
      setStove(PropertyProfile.stove);
      setFridge(PropertyProfile.fridge);
      setWaterPurifier(PropertyProfile.waterPurifier);
      setMicrowave(PropertyProfile.microwave);
      setModularKitchen(PropertyProfile.modularKitchen);
      setChimney(PropertyProfile.chimney);
      setDinningTable(PropertyProfile.dinningTable);
      setCurtains(PropertyProfile.curtains);
      setExhaustFan(PropertyProfile.exhaustFan);
    }
  }, [PropertyProfile]);

  return (
    <>
      <div className="AdditionalRooms-widget">
        <div className="widgetbox_white_box__1v7xz undefined false false">
          <div className="widgetbox_header_text__2BXc_">
            <div className="xidHeading_PageHeadingwrap__3z0kL">
              <h4>
                <div className="section_header_semiBold xidHeading_PageHeading__3ZQJk">
                  <div>
                    <span className="xidHeading_headingText__Ulc2a">
                      Furnishing
                    </span>
                  </div>
                </div>
              </h4>
            </div>
          </div>
          <div className="false">
            <div
              id="furnish-input"
              className="pageComponent"
              style={{ textAlign: "left" }}
            >
              <div id="furnish">
                <div className="tagWrapper_tags_wrapper__KIRJJ  multiple_input">
                  <div
                    id="1"
                    className={
                      furnish === true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setFurnish(true);
                      //   props.getFurnishingType(furnish);
                      setSemifurnish(false);
                      setUnfurnish(false);
                    }}
                  >
                    <span>Furnished</span>
                  </div>
                  <div
                    id="2"
                    className={
                      semifurnish === true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setFurnish(false);
                      setSemifurnish(true);
                      // props.getFurnishingType(semifurnish)
                      setUnfurnish(false);
                    }}
                  >
                    <span>Semi-furnished</span>
                  </div>
                  <div
                    id="1"
                    className={
                      unfurnish === true
                        ? "pageComponent radioInput_textOnly__1r7GL radioInput_active__2BRQm active"
                        : "pageComponent radioInput_textOnly__1r7GL "
                    }
                    onClick={() => {
                      setFurnish(false);
                      setSemifurnish(false);
                      setUnfurnish(true);
                      // props.getFurnishingType(unfurnish)
                    }}
                  >
                    <span>Un-furnished</span>
                  </div>
                </div>
              </div>
              {furnish === true || semifurnish === true ? (
                <>
                  <div className="FurnishingDesktopDetails-widget">
                    <div className="furnishingCustom_inputBoxWrap__wzEjO undefined">
                      <div className="caption_strong_medium">
                        At least three furnishings are mandatory for furnished
                      </div>
                      <div className="furnishingCustom_inline_list_wrap__3h2HE">
                        <div className="furnishing_Light-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {light > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setLight(light - 1);
                                        props.setLight(light - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}

                                <span className="caption_strong_large">
                                  {light}
                                </span>
                                <span
                                  onClick={() => {
                                    setLight(Number(light) + 1);
                                    props.setLight(Number(light) + 1);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>Light</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Fan-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {fans > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setFans(fans - 1);
                                        props.setFans(fans - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {fans}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setFans(Number(fans) + 1);
                                    props.setFans(Number(fans) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>Fans</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Ac-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {ac > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setAc(ac - 1);
                                        props.setAc(ac - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {ac}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setAc(Number(ac) + 1);
                                    props.setAc(Number(ac) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>AC</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Tv-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {tv > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setTv(tv - 1);
                                        props.setTv(tv - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {tv}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setTv(Number(tv) + 1);
                                    props.setTv(Number(tv) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>TV</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Bed-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {beds > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setBeds(beds - 1);
                                        props.setBeds(beds - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {beds}
                                </span>
                                <span
                                  onClick={() => {
                                    setBeds(Number(beds) + 1);
                                    props.setBeds(Number(beds) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>Beds</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Ward-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {wardrobe > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setWardrobe(wardrobe - 1);
                                        props.setWardrobe(wardrobe - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {wardrobe}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setWardrobe(Number(wardrobe) + 1);
                                    props.setWardrobe(Number(wardrobe) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>Wardrobe</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Gey-input">
                          <div className="">
                            <div className="body_med counter_counterWrap__U0_kk">
                              <div className="counter_counter_wrapper__amSb0  counter_smallSize__10lVe">
                                {geyser > 0 ? (
                                  <>
                                    {" "}
                                    <span
                                      className="caption_subdued_large"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setGeyser(geyser - 1);
                                        props.setGeyser(geyser - 1);
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className="caption_subdued_large"
                                      style={{
                                        cursor: "default",
                                        opacity: "0.2",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <i>
                                        <BiMinus />
                                      </i>
                                    </span>
                                  </>
                                )}
                                <span className="caption_strong_large">
                                  {geyser}
                                </span>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setGeyser(Number(geyser) + 1);
                                    props.setGeyser( Number(geyser) + 1);
                                  }}
                                >
                                  <i>
                                    <BiPlus />
                                  </i>
                                </span>
                              </div>
                              <div className="body_med counter_counterWrap__U0_kk">
                                <span>Geyser</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Sofa-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={sofa === true}
                                onChange={() => {
                                  setSofa(!sofa);
                                  props.setSofa(!sofa);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Sofa</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Washmchn-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={washingMachine === true}
                                onChange={() => {
                                  setWashingMachine(!washingMachine);
                                  props.setWashingMachine(!washingMachine);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Washing Machine</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Stv-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={stove === true}
                                onChange={() => {
                                  setStove(!stove);
                                  props.setStove(!stove);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Stove</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Ref-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={fridge === true}
                                onChange={() => {
                                  setFridge(!fridge);
                                  props.setFridge(!fridge);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Fridge</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Wtrpurfr-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={waterPurifier === true}
                                onChange={() => {
                                  setWaterPurifier(!waterPurifier);
                                  props.setWaterPurifier(!waterPurifier);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Water Purifier</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Microwave-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={microwave === true}
                                onChange={() => {
                                  setMicrowave(!microwave);
                                  props.setMicrowave(!microwave);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Microwave</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Kit-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={modularKitchen === true}
                                onChange={() => {
                                  setModularKitchen(!modularKitchen);
                                  props.setModularKitchen(!modularKitchen);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Modular Kitchen</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Chmny-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={chimney === true}
                                onChange={() => {
                                  setChimney(!chimney);
                                  props.setChimney(!chimney);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Chimney</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Dinngtbl-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={dinningTable === true}
                                onChange={() => {
                                  setDinningTable(!dinningTable);
                                  props.setDinningTable(!dinningTable);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Dinning Table</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Curt-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={curtains === true}
                                onChange={() => {
                                  setCurtains(!curtains);
                                  props.setCurtains(!curtains);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Curtains</label>
                            </div>
                          </div>
                        </div>
                        <div className="furnishing_Exhstfn-input">
                          <div className="body_med toggleInput_furnishingCheck__1-nNC">
                            <div className="">
                              <input
                                type="checkbox"
                                id="furnishing_Sofa"
                                checked={exhaustFan === true}
                                onChange={() => {
                                  setExhaustFan(!exhaustFan);
                                  props.setExhaustFan(!exhaustFan);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <label htmlFor="">Exhaust Fan</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FurnishingTemp;