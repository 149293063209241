import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillSearchHeartFill, BsMicFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { BiCurrentLocation, BiDownArrow } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { FiSearch } from "react-icons/fi";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import { Badge } from "react-bootstrap";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import {
  searchPost,
  setSearchComponentStatus,
  setcommercialStatus,
  setsearchObjects,
} from "../../redux/search/SearchSlice";

import MainSearchcomponents from "./MainSearchcomponents";
import {
  setPropertySearchBySelectionCat,
  setsingleProperty,
} from "../../redux/property/PropertySlice";

const HomeBanner = () => {
  const { isAuth, loginData } = useSelector((store) => store.Athentication);
  const { propertySearchBySelectionCat } = useSelector(
    (store) => store.Property
  );
  const { searchComponentStatus, commercialStatus } = useSelector(
    (state) => state.Search
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef();
  const mainref = useRef();

  const [showResults, setShowResults] = useState(false);
  const [mainshowResults, setMainShowResults] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [search, setSearch] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const [showresult, setShowresult] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState("Buy");
  const [commerChange, setcommerChange] = useState(false);

  const [supercat, setSupercat] = useState("Residential");
  const [cat, setCat] = useState("Sell");
  const [checkboxArray, setCheckboxArray] = useState([]);

  const [propertySearch, setPropertySearch] = useState([]);

  const [propertySearchShow, setPropertySearchShow] = useState([]);
  const [propertySearchProject, setPropertySearchProject] = useState([]);

  const [selectedSubcat, setSelectedSubcat] = useState([]);
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const [selectedConustruction, setSelectedConustruction] = useState([]);
  const [selectedPostedBy, setSelectedPostedBy] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([]);
  const [selectedFurnished, setSelectedFurnished] = useState([]);
  const [selectedSharing, setSelectedSharing] = useState([]);
  const [selectedAvailables, setSelectedAvailables] = useState([]);
  const [selectedSeatType, setselectedSeatType] = useState([]);
  const [selectedNoSeat, setselectedNoSeat] = useState([]);
  const [selectedPlan, setselectedPlan] = useState([]);
  const [selectLand, setSelectLand] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectProjectStatus, setSelectProjectStatus] = useState([]);

  const [propertySearchShowByCity, setPropertySearchShowByCity] = useState([]);

  const [propertyShowByApart, setPropertyShowByApart] = useState([]);
  const [showAllApart, setShowAllApart] = useState([]);
  const [citylist, setCitylist] = useState([]);

  // Nishitha states----
  const [buy, setBuy] = useState(true);
  const [rent, setRent] = useState(false);
  const [pg_coliving, setpg_coliving] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const [coworking, setCoworking] = useState(false);
  const [plots_land, setPlots_lands] = useState(false);
  const [projects, setProjects] = useState(false);

  const handleBuy = () => {
    setBuy(true);
    setRent(false);
    setpg_coliving(false);
    setCommercial(false);
    setCoworking(false);
    setPlots_lands(false);
    setProjects(false);
  };
  const handleRent = () => {
    setBuy(false);
    setRent(true);
    setpg_coliving(false);
    setCommercial(false);
    setCoworking(false);
    setPlots_lands(false);
    setProjects(false);
  };
  const handlepg_coliving = () => {
    setBuy(false);
    setRent(false);
    setpg_coliving(true);
    setCommercial(false);
    setCoworking(false);
    setPlots_lands(false);
    setProjects(false);
  };
  const handleCommercial = () => {
    setBuy(false);
    setRent(false);
    setpg_coliving(false);
    setCommercial(true);
    setCoworking(false);
    setPlots_lands(false);
    setProjects(false);
  };
  const handleCoworking = () => {
    setBuy(false);
    setRent(false);
    setpg_coliving(false);
    setCommercial(false);
    setCoworking(true);
    setPlots_lands(false);
    setProjects(false);
  };
  const handlePlots_land = () => {
    setBuy(false);
    setRent(false);
    setpg_coliving(false);
    setCommercial(false);
    setCoworking(false);
    setPlots_lands(true);
    setProjects(false);
  };
  const handleProjects = () => {
    setBuy(false);
    setRent(false);
    setpg_coliving(false);
    setCommercial(false);
    setCoworking(false);
    setPlots_lands(false);
    setProjects(true);
  };

  // end----------
  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/property/allcities`;
      const fetchProperty = await axios.get(url);
      try {
        if (fetchProperty.data.success === true) {
          const cityresult = fetchProperty.data.cities;
          setCitylist(cityresult);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/apartmentswithcity`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.apartment;
              setShowAllApart(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}
  }, [search]);

  useEffect(() => {
    if (propertySearch.length > 0 && search !== "") {
      const searchfilter = propertySearch.filter((item) =>
        item.locality.toLowerCase().match(search.toLowerCase())
      );
      setPropertySearchShow(searchfilter.slice(0, 5));

      const searchfilterbyapart = showAllApart.filter((item) =>
        item.apartment.toLowerCase().match(search.toLowerCase())
      );
      setPropertyShowByApart(searchfilterbyapart.slice(0, 5));

      const searchfilterbycity = citylist.filter((item) =>
        item.toLowerCase().match(search.toLowerCase())
      );
      setPropertySearchShowByCity(searchfilterbycity.slice(0, 5));
    }
  }, [propertySearch, search, citylist, showAllApart]);
  useEffect(() => {
    dispatch(setsingleProperty(""));
  }, []);

  useEffect(() => {
    const clickoutside = (e) => {
      if (
        mainshowResults &&
        mainref.current &&
        !mainref.current.contains(e.target)
      ) {
        setMainShowResults(false);
        setToggle(!toggle);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [mainshowResults]);

  //for search bar strt

  useEffect(() => {
    try {
      if (search.length >= 2) {
        const fetchdata = async () => {
          const url = `${Baseurl}/api/v1/property/localitieswithcity`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.locality;
              setPropertySearch(searchresult);
            }
          } catch (error) {}
        };

        fetchdata();
      }
    } catch (error) {}

    try {
      if (search.length >= 2) {
        const fetchPropertyByProjects = async () => {
          const url = `${Baseurl}/api/v1/property/allPropertyproject/${search}`;
          const fetchProperty = await axios.get(url);
          try {
            if (fetchProperty.data.success === true) {
              const searchresult = fetchProperty.data.properties;
              setPropertySearchProject(searchresult);
            }
          } catch (error) {}
        };

        fetchPropertyByProjects();
      }
    } catch (error) {}

    setShowResults(true);
  }, [search]);

  //for search bar end

  const storeAllApartInarray = (e, data) => {
    let apartmentArray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter(
        (seitem) => seitem === data.apartment
      );
      if (findserchname.length === 0) {
        apartmentArray = [data.apartment, ...searchArray];
        setSearchArray(apartmentArray);
      }
    } else {
      apartmentArray = [data.apartment];
      setSearchArray(apartmentArray);
    }
    setSearch("");
    setShowResults(false);
  };

  const storeCityAllInarray = (e, data) => {
    let cityarray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter((seitem) => seitem === data);
      if (findserchname.length === 0) {
        cityarray = [data, ...searchArray];
        setSearchArray(cityarray);
      }
    } else {
      cityarray = [data];
      setSearchArray(cityarray);
    }
    setSearch("");
    setShowResults(false);
  };

  const storeCityInarray = (e, data) => {
    let localirtarray = [];
    if (searchArray.length > 0) {
      const findserchname = searchArray.filter(
        (seitem) => seitem === data.locality
      );
      if (findserchname.length === 0) {
        localirtarray = [data.locality, ...searchArray];
        setSearchArray(localirtarray);
      }
    } else {
      localirtarray = [data.locality];
      setSearchArray(localirtarray);
    }

    setSearch("");
    setShowResults(false);
  };

  const removesearch = (data) => {
    const filteredArr = searchArray.filter((item) => item !== data);
    setSearchArray(filteredArr);
  };

  const residential = (e, value) => {
    setSupercat("Residential");
    // setSubcat("All");
    if (value === "Buy") {
      setCat("Sell");
    } else if (value === "Rent") {
      setCat("Rent");
    } else if (value === "PG/Co-living") {
      setCat("PG/Co-living");
    } else if (value === "Coworking") {
      setCat("Coworking");
    } else if (value === "Plots/Land") {
      setCat("Sell");
    } else if (value === "Projects") {
      setCat("Sell");
    }
  };

  const commercialClick = () => {
    setcommerChange(!commerChange);
    console.log(commerChange, "commerChange");

    if (commerChange === false) {
      setCat("Sell");
      setSupercat("Commercial");
    }
  };

  const searchRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/property-info/${data.apartment}/${data.propertyID}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };

  const projectRedirect = (e, data) => {
    e.preventDefault();

    navigate(`/project-info/${data.apartment}`);

    if (isAuth) {
      const formdata = {
        userId: loginData._id,
        name: loginData.name,
        userMobile: loginData.mobile,
        propertyId: data._id,
        city: data.city,
        locality: data.locality,
        apartment: data.apartment,
      };
      dispatch(searchPost(formdata));
      setShowResults(false);
    }
  };

  const searchChange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  const searchClick = () => {
    let findsearch = search;
    if (findsearch === "") {
      findsearch = "Hyderabad";
    }
    let mysubcat = "All";
    let mergeSubcat = [...selectedSubcat, ...checkboxArray];
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }

    let finalLocalityArray = [];
    if (searchArray.length === 0) {
      finalLocalityArray = ["Hyderabad"];
    } else {
      finalLocalityArray = searchArray;
    }

    const searchFormdta = {
      selectedSubcat: selectedSubcat,
      selectedCity: finalLocalityArray,
      selectedBedrooms: selectedBedrooms,
      selectedConustruction: selectedConustruction,
      selectedPostedBy: selectedPostedBy,
      propertyPrice: propertyPrice,
      selectedSharing: selectedSharing,
      selectedAvailables: selectedAvailables,
      selectedFurnished: selectedFurnished,
      selectedSeatType: selectedSeatType,
      selectedPlan: selectedPlan,
      selectedNoSeat: selectedNoSeat,
      selectLand: selectLand,
      selectedArea: selectedArea,
      selectProjectStatus: selectProjectStatus,
    };
    dispatch(setsearchObjects(searchFormdta));

    if (propertySearchBySelectionCat === "") {
      navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${findsearch}`);
    } else {
      navigate(
        `/property-search/${supercat}/${propertySearchBySelectionCat}/${mysubcat}/${findsearch}`
      );
    }
    dispatch(setPropertySearchBySelectionCat(""));
    setShowresult(false);
    setSelectedHeader("Buy");
    setCheckboxArray([]);
  };

  const searchsubmit = () => {
    let findsearch = search;
    if (findsearch === "") {
      findsearch = "Hyderabad";
    }
    let mysubcat = "All";
    if (checkboxArray.length > 0) {
      mysubcat = checkboxArray.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "-");
      mysubcat = mysubcat.replaceAll(" ", "-");
    }
    if (propertySearchBySelectionCat === "") {
      navigate(`/property-search/${supercat}/${cat}/${mysubcat}/${findsearch}`);
    } else {
      navigate(
        `/property-search/${supercat}/${propertySearchBySelectionCat}/${mysubcat}/${findsearch}`
      );
    }
    dispatch(setPropertySearchBySelectionCat(""));
    setShowresult(false);
  };

  const getDetails = (values) => {};
  const getSubcatDetails = (values) => {
    let mysubcat = "all";
    if (values.length > 0) {
      mysubcat = values.join();
      mysubcat = mysubcat.replaceAll(",", "-");
      mysubcat = mysubcat.replaceAll("/", "&");
      mysubcat = mysubcat.replaceAll(" ", "=");
    }
    setSelectedSubcat(values);
  };
  const getBudgetDetails = (values) => {
    setPropertyPrice(values);
  };
  const getBedRoomDetails = (values) => {
    setSelectedBedrooms(values);
  };
  const getConustructionDetails = (values) => {
    setSelectedConustruction(values);
  };
  const getPostedDetailsDetails = (values) => {
    setSelectedPostedBy(values);
  };
  const getFurnishedDetails = (values) => {
    setSelectedFurnished(values);
  };
  const getSharingDetails = (values) => {
    setSelectedSharing(values);
  };
  const getavailabilityDetails = (values) => {
    setSelectedAvailables(values);
  };
  const getProjectStatusDetails = (values) => {
    setSelectProjectStatus(values);
  };
  const getAreaDetails = (values) => {
    setSelectedArea(values);
  };
  const getLandDetails = (values) => {
    setSelectLand(values);
  };
  const getNoSeatDetails = (values) => {
    setselectedNoSeat(values);
  };
  const getSeatTypeDetails = (values) => {
    setselectedSeatType(values);
  };
  const getPlansDetails = (values) => {
    setselectedPlan(values);
  };

  return (
    <>
      <div className="maindivcontainer">
        <div className="hero-title hero-title_small banner-title banner">
          <h4 className="hero-title_small1">Real Estate Searching Platform</h4>
          <Link>
            <h2>
              Find The House of Your Dream <br />
              Using Our Platform
            </h2>
          </Link>
        </div>
        <div className="containerofinpagesearchbox" ref={mainref}>
          <div className="innercontainerofinpagesearchbox">
            <div className="sub_container_of_inpage">
              <div className="sub_inner_container">
                <div
                  onClick={(e) => {
                    handleBuy();
                    residential(e, "Buy");
                    setSelectedHeader("Buy");
                    dispatch(setSearchComponentStatus(0));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  buy
                  {buy ? <hr className="hr_content" /> : ""}
                </div>
                <div
                  onClick={(e) => {
                    handleRent();
                    residential(e, "Rent");
                    setSelectedHeader("Rent");
                    dispatch(setSearchComponentStatus(1));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  rent
                  {rent ? <hr className="hr_content" /> : ""}
                </div>
                <div
                  onClick={(e) => {
                    handlepg_coliving();
                    residential(e, "PG/Co-living");
                    setCat("Pg");
                    setSelectedHeader("PG");
                    dispatch(setSearchComponentStatus(2));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  PG/Co-living
                  {pg_coliving ? <hr className="hr_content" /> : ""}
                </div>
                <div
                  onClick={(e) => {
                    handleCommercial();
                    commercialClick(e);
                    setSelectedHeader("commercial");
                    dispatch(setSearchComponentStatus(3));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  Commercial
                  {commercial ? <hr className="hr_content" /> : ""}
                </div>
                {/* <div onClick={(e) => {
                  handleCoworking();
                  residential(e, "Coworking");
                  setSelectedHeader("Coworking");
                  dispatch(setSearchComponentStatus(4));
                  setToggle(false);
                }} id="innerdemo_sub">Coworking
                  {coworking ? <hr className="hr_content" /> : ''}
                </div> */}
                <div
                  onClick={(e) => {
                    handlePlots_land();
                    residential(e, "Plots/Land");
                    setCheckboxArray(["Plot", "Land"]);
                    setSelectedHeader("Plots/Land");
                    dispatch(setSearchComponentStatus(5));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  <div>
                    Plots/Land
                    <span
                      className="newbadge"
                      style={{ background: "#e62878" }}
                    >
                      NEW
                    </span>
                  </div>
                  {plots_land ? <hr className="hr_content" /> : ""}
                </div>
                <div
                  onClick={(e) => {
                    handleProjects();
                    residential(e, "Projects");
                    setSelectedHeader("Projects");
                    dispatch(setSearchComponentStatus(6));
                    setToggle(false);
                  }}
                  id="innerdemo_sub"
                >
                  Projects
                  {projects ? <hr className="hr_content" /> : ""}
                </div>
              </div>
            </div>
            <div className="subtwo_container_of_inpage">
              <div className="tab__tabsContent">
                <form onSubmit={searchsubmit}>
                  <div className="inPageSearchBox__searchTabWrap">
                    <div className="inPageSearchBox__inputFilterOptions">
                      <div className="inPageSearchBox__basicFilter">
                        <div className="list_header_semiBold  inPageSearchBox__searchDropdown">
                          {searchComponentStatus === 3 ? (
                            <>
                              <div
                                onClick={() => {
                                  dispatch(setcommercialStatus(0));
                                  setToggle(!toggle);
                                  setMainShowResults(true);
                                }}
                              >
                                Buy
                                {!toggle && commercialStatus === 0 ? (
                                  <>
                                    <BsChevronDown className="arrowicon" />
                                  </>
                                ) : (
                                  <>
                                    <BsChevronUp className="arrowicon" />
                                  </>
                                )}
                              </div>
                              <div
                                onClick={() => {
                                  dispatch(setcommercialStatus(1));
                                  setToggle(!toggle);
                                  setMainShowResults(true);
                                }}
                              >
                                All Commercial
                                {!toggle && commercialStatus === 1 ? (
                                  <>
                                    <BsChevronUp className="arrowicon" />
                                  </>
                                ) : (
                                  <>
                                    <BsChevronDown className="arrowicon" />
                                    {/* <BsChevronUp className="arrowicon" /> */}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                onClick={() => {
                                  setToggle(!toggle);
                                  setMainShowResults(true);
                                }}
                              >
                                All Residential
                                {!toggle ? (
                                  <>
                                    <BsChevronDown className="arrowicon" />
                                  </>
                                ) : (
                                  <>
                                    <BsChevronUp className="arrowicon" />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          {mainshowResults && (
                            <>
                              {toggle ? (
                                <MainSearchcomponents
                                  getDetails={getDetails}
                                  getSubcatDetails={getSubcatDetails}
                                  getBudgetDetails={getBudgetDetails}
                                  getBedRoomDetails={getBedRoomDetails}
                                  getConustructionDetails={
                                    getConustructionDetails
                                  }
                                  getPostedDetailsDetails={
                                    getPostedDetailsDetails
                                  }
                                  getFurnishedDetails={getFurnishedDetails}
                                  getSharingDetails={getSharingDetails}
                                  getavailabilityDetails={
                                    getavailabilityDetails
                                  }
                                  getProjectStatusDetails={
                                    getProjectStatusDetails
                                  }
                                  getAreaDetails={getAreaDetails}
                                  getLandDetails={getLandDetails}
                                  getNoSeatDetails={getNoSeatDetails}
                                  getSeatTypeDetails={getSeatTypeDetails}
                                  getPlansDetails={getPlansDetails}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>

                        <div className="inPageSearchBox__searchField" ref={ref}>
                          <div
                            className=" inPageSearchBox__searchFieldInput"
                            style={{ position: "relative" }}
                          >
                            <div className="icon_search">
                              <FiSearch />
                            </div>
                            {searchArray.length > 0 ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    className=""
                                    style={{
                                      background: "#f0f9ff",
                                      border: "1px solid #a3daff",
                                      borderRadius: "50px",
                                      padding: "5px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                      paddingRight: "10px",
                                      paddingLeft: "10px",
                                      flexDirection: "row",
                                      display: "flex",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <p
                                      className=""
                                      style={{ color: "#000", margin: 0 }}
                                    >
                                      {searchArray[0]}
                                    </p>
                                    <RxCross2
                                      onClick={() =>
                                        removesearch(searchArray[0])
                                      }
                                      color="#0078db"
                                      size={20}
                                    />
                                  </div>
                                  {searchArray.length > 1 ? (
                                    <>
                                      <div
                                        className=""
                                        style={{
                                          background: "#f0f9ff",
                                          border: "1px solid #a3daff",
                                          borderRadius: "50px",
                                          padding: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          paddingRight: "10px",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <p
                                          className=""
                                          style={{ color: "#000", margin: 0 }}
                                        >
                                          + {searchArray.length - 1} More
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="inputfield_container">
                              <input
                                type="text"
                                name="keyword"
                                className=""
                                id="keyword2"
                                autoComplete="off"
                                placeholder='Search "Hyderabad"'
                                ref={ref}
                                value={search}
                                onChange={(e) => searchChange(e)}
                              />
                              <div className="component__suggest" />
                            </div>
                          </div>

                          {showResults && (
                            <>
                              {propertyShowByApart.length > 0 ? (
                                <>
                                  <div className="serchsugist">
                                    {/* <ul>
                                      {propertyShowByApart &&
                                        propertyShowByApart.map(
                                          (apart, index3) => (
                                            <>
                                              <Link
                                                to={`/property-info/${
                                                  apart.apartment
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  apart.apartment.slice(1)
                                                }/${apart.propertyID}`}
                                                key={index3}
                                              >
                                                <li className="d-flex py-1 liclas">
                                                  <div
                                                    className="mx-1"
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <h6>{apart.apartment}</h6>
                                                    <h6>{apart.subCategory}</h6>
                                                  </div>
                                                </li>
                                              </Link>
                                            </>
                                          )
                                        )}
                                    </ul> */}
                                    <ul>
                                      {propertyShowByApart &&
                                        propertyShowByApart.map(
                                          (apart, index3) => (
                                            <>
                                              <li
                                                className="d-flex py-1 liclas"
                                                onClick={(e) =>
                                                  storeAllApartInarray(e, apart)
                                                }
                                                key={index3}
                                              >
                                                <div
                                                  className="mx-1"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h6>{apart.apartment}</h6>
                                                  <h6>{apart.subCategory}</h6>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* {propertyShowByApart.length > 0 ? (
                              <>
                                <div className="serchsugist">
                                  <ul>
                                    {propertyShowByApart &&
                                      propertyShowByApart.map(
                                        (apart, index3) => (
                                          <>
                                            <li
                                              className="d-flex py-1 liclas"
                                              onClick={(e) =>
                                                storeApartAllInarray(e, apart)
                                              }
                                              key={index3}
                                            >
                                              <div
                                                className="mx-1"
                                                style={{
                                                  textTransform: "capitalize",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <h6>{apart.apartment}</h6>
                                                <h6>Apartment</h6>
                                              </div>
                                            </li>
                                          </>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </>
                            ) : (
                              <></>
                            )} */}

                              {propertySearchShowByCity.length > 0 ? (
                                <>
                                  <div className="serchsugist">
                                    <ul>
                                      {propertySearchShowByCity &&
                                        propertySearchShowByCity.map(
                                          (city, index2) => (
                                            <>
                                              <li
                                                className="d-flex py-1 liclas"
                                                onClick={(e) =>
                                                  storeCityAllInarray(e, city)
                                                }
                                                key={index2}
                                              >
                                                <div
                                                  className="mx-1"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h6>{city}</h6>
                                                  <h6>All</h6>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {propertySearchShow.length > 0 ? (
                                <>
                                  <div className="serchsugist">
                                    <ul>
                                      {propertySearchShow &&
                                        propertySearchShow.map(
                                          (data, index) => (
                                            <>
                                              <li
                                                className="d-flex py-1 liclas"
                                                onClick={(e) =>
                                                  // searchRedirect(e, data)
                                                  storeCityInarray(e, data)
                                                }
                                                key={index}
                                              >
                                                <div
                                                  className="mx-1"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h6>
                                                    {data.locality +
                                                      "," +
                                                      data.city}
                                                  </h6>
                                                  <h6 key={index}>
                                                    {data.city}
                                                  </h6>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {propertySearchProject.length > 0 ? (
                                <>
                                  <div className="serchsugist">
                                    <ul>
                                      {propertySearchProject &&
                                        propertySearchProject.map(
                                          (data, index) => (
                                            <>
                                              {" "}
                                              <li
                                                className="d-flex py-1 liclas"
                                                onClick={(e) =>
                                                  projectRedirect(e, data)
                                                }
                                              >
                                                <div
                                                  className="mx-1"
                                                  style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <h6 key={index}>
                                                    {data.apartment}
                                                  </h6>
                                                  <h6 key={index}>Project</h6>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {/* <div
                        className="inPageSearchBox__nearMe"
                        style={{
                          cursor: "pointer",
                          height: 30,
                          whiteSpace: "nowrap",
                          borderRadius: 36,
                          display: "flex",
                          alignItems: "center",
                          marginRight: 12,
                        }}
                      >
                        <img
                          src="https://www.99acres.com/universalapp/img/nearMeV2.png"
                          width={40}
                          height={40}
                        />
                      </div> */}
                      <div className="inPageSearchBox__searchBtn">
                        <div>
                          <div>
                            <img
                              className="image"
                              src="https://www.99acres.com/universalapp/img/mic.png"
                              style={{ width: "40px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inPageSearchBox__searchBtn">
                        <button
                          type="submit"
                          className="button"
                          onClick={searchClick}
                          id="searchbtn"
                        >
                          <span className="">Search</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
